import axios from 'axios'

export function setAuthHeaderFromStore(store) {
  axios.interceptors.request.use(
    config => {
      // Determine the user type based on the state; this may vary depending on your specific structure
      const userTypes = ['customer', 'administrator']

      userTypes.forEach(userType => {
        const userModule = store.state[userType]
        if (userModule && userModule.isUserAuthenticated) {
          const token = userModule.userInfo.authenticationToken
          config.headers.Authorization = `Bearer ${token}`
        }
      })

      return config
    },
    error => Promise.reject(error),
  )
}
