<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="270"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/logo.png?v=2')"
          max-height="60px"
          max-width="60px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            {{ companyName }}
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <template v-for="(item, index) in navigationItems">
        <template v-if="item.children">
          <nav-menu-group
            :key="item.title"
            :title="item.title"
            :icon="item.icon"
          >
            <template v-for="child in item.children">
              <nav-menu-link
                :key="child.title"
                class="ml-4"
                :title="child.title"
                :to="child.to"
                :icon="child.icon"
              ></nav-menu-link>
            </template>
          </nav-menu-group>
        </template>
        <template v-else>
          <nav-menu-link
            :key="item.title"
            :title="item.title"
            :to="item.to"
            :icon="item.icon"
          ></nav-menu-link>
        </template>
      </template>
    </v-list>
    <nav-menu-link
      title="Settings"
      :to="{ name: 'settings' }"
      :icon="icons.mdiCog"
      class="settings-menu"
    ></nav-menu-link>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiWeb,
  mdiReceipt,
  mdiCurrencyUsdCircleOutline,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import NavMenuLink from './components/NavMenuLink.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import internetConnectionTypeEnum from '@/enums/internetConnectionTypeEnum'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    NavMenuLink,
    NavMenuGroup,
  },
  mixins: [hasPermission],
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      navigationItems: [
        {
          title: 'Dashboard',
          to: { name: 'dashboard' },
          icon: mdiHomeOutline,
        },
        {
          title: 'Internet Plans',
          icon: mdiWeb,
          to: {
            name: 'internet-plans',
          },
        },
        {
          title: 'Invoices',
          to: { name: 'invoices' },
          icon: mdiReceipt,
        },
        {
          title: 'Payments',
          to: { name: 'payments' },
          icon: mdiCurrencyUsdCircleOutline,
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['companyName']),
  },
  setup() {
    return {
      internetConnectionTypeEnum,
      icons: {
        mdiHomeOutline,
        mdiWeb,
        mdiReceipt,
        mdiCurrencyUsdCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list {
    margin-bottom: 48px; /* Adjust the value as needed to create space between menu items and the Settings menu */
  }
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
  .settings-menu {
    position: absolute;
    bottom: 0; /* Adjust the value as needed */
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    background-color: var(--v-aura-base);
    z-index: 1; /* Ensure it appears above other menu items */
  }
}
</style>
