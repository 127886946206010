<template>
  <component :is="resolveLayout">
    <nprogress-container></nprogress-container>
    <router-view :key="$route.fullPath"></router-view>
  </component>
</template>

<script>
import { computed } from '@vue/composition-api'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    NprogressContainer,
  },
  computed: {
    ...mapGetters(['isDarkThemeEnabled', 'isUserAuthenticated',
      'userInfo']),
  },
  watch: {
    isUserAuthenticated: {
      handler() {
        this.setAuthHeader() // Update the Authorization header when the authentication status changes
      },
      immediate: true, // Call the handler immediately after registration, so it also runs on component mount
    },
  },
  mounted() {
    this.$vuetify.theme.dark = this.isDarkThemeEnabled

    // Set up Axios response interceptor
    axios.interceptors.response.use(
      response => response, // Pass through successful responses
      error => {
        const { status } = error.response

        // Handle server down (503)
        if (status === 503) {
          console.log('Server is down')
          this.$toast.error(error.response.data.message)
        }

        // Handle unauthorized access (401)
        if (status === 401) {
          this.handleUnauthorizedError()
        }

        return Promise.reject(error) // Pass error to the next handler
      },
    )
  },
  methods: {
    ...mapActions(['clearUser']),
    setAuthHeader() {
      delete axios.defaults.headers.common.Authorization
      if (this.isUserAuthenticated) {
        axios.defaults.headers.common.Authorization = `Bearer ${this.userInfo.authenticationToken}`
      }
    },
    isHotspotRoute() {
      const hotspotRoutes = [
        'hotspot-internet-plans',
        'hotspot-login',
        'hotspot-register',
      ]

      return hotspotRoutes.includes(this.$router.currentRoute.name)
    },

    // Handle actions when receiving a 401 error
    handleUnauthorizedError() {
      const currentRoute = this.$router.currentRoute.name

      if (this.isHotspotRoute() && currentRoute !== 'hotspot-register') {
        this.$router.replace({ name: 'hotspot-register' })
      } else if (currentRoute !== 'login') {
        this.$router.replace({ name: 'login' })
        this.clearUser()
      }
    },
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
}
</script>

<style>
.clickable-data-table tbody tr:hover {
  cursor: pointer;
}
</style>
