export default {
  computed: {
    userType() {
      // Determine the user type based on the route path
      return this.$route.path.startsWith('/admin') ? 'administrator' : 'customer'
    },
    userPermissions() {
      // Dynamically map the getter based on the userType
      return this.$store.getters[`${this.userType}/userPermissions`]
    },
  },
  methods: {
    can(key) {
      // Check if the user has the specific permission key
      return this.userPermissions.includes(key)
    },
    removeActionsHeader() {
      // Remove the last element from the headers array
      this.headers.pop()
    },
  },
}
